import React, { useState } from 'react'
import { UlupinarSliderProps } from './type'
import { useApp } from '@/core/contexts/app'
import Icon from '@/components/base/icon'
import Anchor from '@/components/base/anchor'
import { useWidth } from '@/core/hooks/useWidthResize'
import Slider from 'react-slick'
import Image from '@/components/base/image/Image'
import { classnames } from 'begonya/functions'
import Button from '@/components/base/button/Button'
import { Container } from '@/components/base/gridview'

const UlupinarSlider: React.FC<UlupinarSliderProps> = ({
  title,
  description,
  items,
}) => {
  // const [activeIndex, setActiveIndex] = useState(0)
  // const handleItemClick = (index: number) => {
  //   setActiveIndex(index)
  // }

  const width = useWidth()
  const app = useApp()
  const settings = {
    dots: false,
    slidesToShow: width < 500 ? 1 : width < 1000 ? 2 : width < 1500 ? 3 : 4,
    slidesToScroll: 1,
    autoplay: false,
    speed: 500,
    autoplaySpeed: 4000,
    cssEase: 'linear',
    arrows: false,
  }
  return (
    <div className="UlupinarSlider">
      <Container size="medium">
        <div className="UlupinarSlider-head">
          <div className="title" dangerouslySetInnerHTML={{ __html: title }} />
          {/* <Anchor className="more">
            <div
              dangerouslySetInnerHTML={{
                __html: app.settings.translations['moreinfo'],
              }}
            />
            <Icon name="arrow-right-alt" />
          </Anchor> */}
        </div>

        <div
          className="UlupinarSlider-desc"
          dangerouslySetInnerHTML={{ __html: description }}
        />

        <Slider {...settings}>
          {items?.map((item, index) => (
            <div className="UlupinarSlider-slider" key={index}>
              <button
                // onClick={() => setActiveIndex(index)}
                className={classnames(
                  'UlupinarSlider-slider-item'
                  // index === activeIndex && 'UlupinarSlider-slider-item--active'
                )}
              >
                <figure>
                  <div className="more">
                    <Image {...item.itemimage} alt={item.itemtitle} />
                  </div>
                </figure>

                <div
                  className="UlupinarSlider-slider-item-text"
                  dangerouslySetInnerHTML={{ __html: item.itemtitle }}
                />
              </button>
            </div>
          ))}
        </Slider>
      </Container>
    </div>
  )
}

export default UlupinarSlider
